/* global $ */
import { map, head, keys, values, isObject } from "lodash";
import CustomConfigurator from "CustomConfigurator";

const elvisBlue = "#00418c";
const elvisOrange = "#EA671F";
const elvisGrey = "#87888a";
const elvisBlack = "#0a0a0a";
const elvisGrey2 = "#424344";
const elvisRed = "#79132d";
const elvisRed2 = "#ff0000";
const elvisGreen = "#0f820a";
const elvisGreen2 = "#04b57e";
const elvisBlue2 = "#152345";
const elvisBlue3 = "#1598e0";
const elvisOrange2 = "#1598e0";
const elvisPurple = "#751750";

// Custom composer config for elvis
CustomConfigurator.set(
  "markdownEditor.colors",
  map(
    [
      { elvisBlue },
      { elvisOrange },
      { elvisGrey },
      { elvisBlack },
      { elvisGrey2 },
      { elvisRed },
      { elvisRed2 },
      { elvisGreen },
      { elvisGreen2 },
      { elvisBlue2 },
      { elvisBlue3 },
      { elvisOrange2 },
      { elvisPurple },
    ],
    (color) => {
      let colorLabel = color;
      let style = color;
      if (isObject(color)) {
        colorLabel = head(keys(color));
        style = head(values(color));
      }
      return {
        label: () => I18n.t(`js.composer.editor.colors.${colorLabel}`),
        style,
      };
    },
  ),
);
